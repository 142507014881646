// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  getBaseURL: function () {
    // return 'https://localhost:8083';
    // return 'http://144.217.53.54:8001';
    // return 'http://179.127.21.54:8083';
    // return 'http://192.168.0.87:8083';
    // return 'http://187.0.205.47:8083';
    return 'https://sgcr-server.crefito7.gov.br:8700'
  }

};

/*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
