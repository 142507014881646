import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CommonsForm} from '../../commons-form';
import {CurrencyPipe} from '@angular/common'
import {ActivatedRoute, Router} from '@angular/router';
import {FilterData} from '../../components/interfaces';
import {CommonsService} from '../../commons-service';
import {GuiaService} from '../guia/guia.service';
import {Guia} from '../guia/guia';
import {SharedService} from '../../shared/shared.service';
import {NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TipoCobrancaService} from "../tipocobranca/tipocobranca.service";
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CobrancaService} from "./cobranca.service";
import {ToastrService} from 'ngx-toastr';
import {NegociacaoService} from "../negociacao/negociacao.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Negociacao} from "../negociacao/negociacao";
import {TipoCobranca} from "../tipocobranca/tipocobranca";
import * as Extenso from 'numero-por-extenso';
//import * as jspdf from 'jspdf';
import {NgxSpinnerService} from 'ngx-spinner';
import {CdaService} from "../cda/cda.service";
import {Cobranca} from "./cobranca";
import {ProcessoAdministrativo} from "../processo-administrativo/processo-administrativo";
import * as moment from "moment";
import {SetorService} from "../setor/setor.service";
import {Setor} from "../setor/setor";
import {ProcessoAdministrativoService} from "../processo-administrativo/processo-administrativo.service";
import {FilterDataHql} from "../../shared/filterdata/filter-data-hql";
import {ColumnType} from "../../shared/filterdata/column-type.enum";
import {FaseProcessoAdministrativo} from "../fase-processo-administrativo/fase-processo-administrativo";
import {WhereClauseHql} from "../../shared/filterdata/where-clause-hql";
import {RetornoBoletoService} from "../retorno-boleto/retorno-boleto.service";
import {RetornoBoleto} from "../retorno-boleto/retorno-boleto";
import {NgxSmartModalService} from "ngx-smart-modal";
import Swal from 'sweetalert2'
import {Usuario} from "../usuario/usuario";
import {Boleto} from "../boleto/boleto";
import {BoletoService} from "../boleto/boleto.service";
import {ClipboardService} from "ngx-clipboard";
import {ProcessoAdministrativoViewComponent} from "../processo-administrativo/processo-administrativo-view/processo-administrativo-view.component";
import {ProcessoAdministrativoFormComponent} from "../processo-administrativo/processo-administrativo-form/processo-administrativo-form.component";
import {ProcessoAdministrativoFaseModalComponent} from "../processo-administrativo/processo-administrativo-fase-modal/processo-administrativo-fase-modal.component";
import {ColumnMatch} from "../../shared/filterdata/column-match.enum";


export interface DialogData {
  animal: string;
  name: string;
}

declare var $: any;

@Component({
  selector: 'app-cobranca-tab',
  templateUrl: './cobranca-tab.component.html',
  styleUrls: ['./cobranca.tab.css']
})
export class CobrancaComponent extends CommonsForm<Guia> implements OnInit, AfterViewInit {

  animal: string;
  name: string;

  datavencimento = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  dtOptions: DataTables.Settings = {};

  public isCollapsed = true;
  guiaForm: FormGroup;
  guiaUpdateForm: FormGroup;
  guiasAgrupadaForm: FormGroup;
  guiasCDAForm: FormGroup;
  negociacaoForm: FormGroup;
  guiaList: FormArray;
  reportname: string;
  guias: Array<Guia>;
  tiposDeCobranca: TipoCobranca[];
  guiaAnosAnteriores = [];
  guiaEmProcessoAdministrativo = [];
  guiasporanuidade: any;
  guiasporanuidadeordenada: [];
  guiasnegociacao: any;
  guiaCanceladas = [];
  guiasNegociacao = [];
  guiasPagas = [];
  guiasAgrupadas = [];
  parcelaAcordo = [];
  guiasAcordoParcela = [];
  taxas = [];
  itemNun: 0;
  exampleData = [];
  valorTotal: number = 0.0;
  valorTotalDebitos: number = 0;
  verCancelada: boolean;
  negociacoes: Array<Negociacao> = [];
  totalDebitos: number = 0.0;
  totalPago: number = 0.0;
  boletos: Array<Boleto> = new Array<Boleto>();
  usuario: any;
  activeUser: any;
  setores: Array<Setor> = new Array<Setor>();

  @Input() currentTarget: any;
  @ViewChild(ProcessoAdministrativoViewComponent) paView: ProcessoAdministrativoViewComponent;
  @ViewChild(ProcessoAdministrativoFormComponent) paModalForm: ProcessoAdministrativoFormComponent;
  @ViewChild(ProcessoAdministrativoFaseModalComponent) paModalFase: ProcessoAdministrativoFaseModalComponent;

  constructor(private guiaService: GuiaService,
              public dialog: MatDialog,
              private modalService: NgbModal,
              public ngxModal: NgxSmartModalService,
              private spinner: NgxSpinnerService,
              private negociacaoService: NegociacaoService,
              route: ActivatedRoute,
              router: Router,
              private toastr: ToastrService,
              private sharedService: SharedService,
              private cp: CurrencyPipe,
              private cdaService: CdaService,
              private boletoService: BoletoService,
              private fb: FormBuilder,
              private setorService: SetorService,
              public processoAdmService: ProcessoAdministrativoService,
              private tipocobrancaService: TipoCobrancaService,
              private cobrancaService: CobrancaService,
              private copyService: ClipboardService,
              private retornoBoletoService: RetornoBoletoService) {
    super(guiaService, route, router);
  }

  ngOnInit() {
    this.spinner.show();

    this.guiasnegociacao = [];
    this.totalDebitos = 0.0;
    this.totalPago = 0.0;

    this.usuario = JSON.parse(sessionStorage.getItem("loginResponse"));
    //console.log(this.usuario);
    this.activeUser = this.usuario.usuario;
    //console.log(this.activeUser.id);

    this.beanComponent = this.route.component;
    this.beanType = this.beanComponent.name;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      language: {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };

    this.beanSubscribe = this.route.params.subscribe(params => {
      this.beanId = params['id'];
      this.loadSetores();

      //novo carregamento
      this.loadAllsDebitos();

      //antiga carga
      /*this.guiaService.getbyCpad(this.beanId).subscribe(response => {
        this.activeBean = <any>response;
        this.guias = <any>response;
        //console.log(this.guias.find(g => Number(g.id) === 1140244));

        this.setGuiasEmAberto(this.guias);
        this.setGuiasnegociacao(this.guias);
        this.setGuiasCanceladas(this.guias);
        this.setGuiasPagas(this.guias);
        this.setGuiasParcelaAcordo(this.guias);
        this.setGuiasEmProcesso(this.guias);
        this.setTaxas(this.guias)
        let beanIdNumber = +this.beanId;
        this.negociacaoService.findNegociacaoById(beanIdNumber).subscribe(response => {
          this.negociacoes = <any>response;
          //console.log(this.negociacoes);
        });

        this.loadSetores();
        this.paView.loadProcessosAdm();
      });*/
    });

    this.getTiposDeCobrana(this.getDefaultFilter());

    this.loadForm();

    this.negociacaoForm = this.fb.group({
      login: null,
      tipoPessoa: null,
      guiasParaNegociar: null,
      dataPrimeiraparcela: null,
      qtdParcelas: null,
      profissional_id: null,
      valorTotal: null,
      anuidade: null

    });

    this.guiaUpdateForm = this.fb.group({
      profissional_id: null,
      datavencimento: null,
      boletoCobrancaList: null,
      guiaList: this.fb.array([this.createItemUpdate()])
    });

    this.guiasAgrupadaForm = this.fb.group({
      profissional_id: null,
      pessoajuridica_id: null,
      consultorio_id: null,
      dataVencimento: null,
      boletoCobrancaList: null,
      guiaList: this.fb.array([this.createItemUpdate()])


    });

    this.guiasCDAForm = this.fb.group({
      profissional_id: null,
      dataVencimento: null,
      boletoCobrancaList: null,
      guiasParaCDA: this.fb.array([this.createItemUpdate()])


    });

    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  ngAfterViewInit() {

  }

  loadAllsDebitos() {
    this.cobrancaService.selecionarDebitos(this.beanId)
      .subscribe(response => {
        if (response) {
          this.guias = response.content as Guia[];
          this.setGuiasEmAberto(this.guias);
          this.setGuiasnegociacao(this.guias);
          this.setGuiasCanceladas(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setGuiasEmProcesso(this.guias);
          this.setTaxas(this.guias);
          this.paView.loadProcessosAdm();
        }
      });

    this.negociacaoService.findNegociacaoById(Number(this.beanId)).subscribe(response => {
      if (response) {
        this.negociacoes = response as unknown as Array<any>;
        //console.log(this.negociacoes);
      }
    });
  }

  loadForm() {
    this.guiaForm = this.fb.group({
      profissional_id: null,
      datavencimento: null,
      boletoCobrancaList: null,
      guiaList: this.fb.array([this.createItemUpdate()])
    });
  }

  loadSetores() {
    this.setorService.loadAll().subscribe(response => {
      if (response) {
        this.setores = <any>response;
      }
    }, error => {
      console.error(error.message);
    });
  }

  acordoActive: any;
  guiasArcodo: any;
  totalDebitosNegociar = 0.0;
  anuidadeNegociacao: any;
  parcelasAcordo: null;
  valorGuiaAdd: number;
  valorGuiaRemove: number;

  negociacaoGuiaListAdd(guia: Guia, e) {
    if (e.target.checked) {
      this.addToNegoArray(guia)
    }

    if (e.target.checked == false) {
      this.removeFromNegoArray(guia);
    }
  }

  negociacaoGuiaListAdd2(guia: Guia, e) {
    let arr: Array<Guia> = this.guias.filter(g => g.guia.includes(guia.guia));

    if (e.target.checked) {
      arr.forEach(g => this.addToNegoArray(g));
    }

    if (e.target.checked == false) {
      arr.forEach(g => this.removeFromNegoArray(g));
    }
  }

  guiaIdsNegociar = [];

  addToNegoArray(guia: Guia) {
    this.totalDebitosNegociar = this.totalDebitosNegociar + guia.valorTotal;
    this.guiasNegociacao.push(guia);
    this.guiaIdsNegociar.push(guia.id);
    this.anuidadeNegociacao = guia.anuidade;
  }

  removeFromNegoArray(guia: Guia) {
    this.totalDebitosNegociar = this.totalDebitosNegociar - guia.valor;
    let id = guia.id;
    for (var i = this.guiasNegociacao.length - 1; i >= 0; --i) {
      if (this.guiasNegociacao[i].id == guia.id) {
        this.guiaIdsNegociar = this.guiaIdsNegociar.filter(function (e) {
          return e !== guia.id
        })
        this.guiasNegociacao.splice(i, 1);
      }
    }
  }

  valorParcela: number;

  criarParcelasNego(e) {

    this.parcelaAcordo = [];
    let quantidadeString = e.value

    let quantidade = +quantidadeString;

    this.valorParcela = this.totalDebitosNegociar / quantidade;

    for (var i = 0; i < quantidade; i++) {
      this.parcelaAcordo.push(
        {
          parcela: i + 1,
          valor: this.valorParcela
        }
      )
    }
  }

  valorParcelaanuidade: number;
  totalAnuidadeParcelar = 0.0;

  criarParcelaAnuidade(e) {
    let quantidadeAnuidade = e.value;
    this.qtdanuidadeparcelas = quantidadeAnuidade;

    let quantidade = +quantidadeAnuidade;

    this.valorParcelaanuidade = this.totalAnuidadeParcelar / quantidade;

  }

  anuidadeParaParcelar: any;

  openAnuidadeParcelada(anuidadeParcelada) {
    this.anuidadeParaParcelar = <FormArray>this.guiaUpdateForm.controls.guiaList;

    const activeModal = this.modalService.open(anuidadeParcelada, {size: 'lg'});
  }

  openAcordoModal(acordoModal) {
    const activeModal = this.modalService.open(acordoModal, {size: 'lg'});
  }

  openAgrupaGuiasModal(agrupaGuiasModal) {
    const activeModal = this.modalService.open(agrupaGuiasModal, {size: 'lg'});
  }

  openCdaGuiasModal(CdaGuiasModal) {
    const activeModal = this.modalService.open(CdaGuiasModal, {size: 'lg'});
  }

  setGuiasEmAberto(guias: Array<Guia>) {
    this.guiaAnosAnteriores = guias.filter(g => g.situacao == "" && g.dataPagamento == null && g.tipo != "72" && (g.tipocobranca_id && g.tipocobranca_id.id.toString() != "56")).sort((g1, g2) => g1.datavencimento < g2.datavencimento ? 1 : -1);
    this.totalDebitos = this.guiaAnosAnteriores.reduce((sum, item) => (Number(item.tipocobranca_id.id) !== 107) ? sum + item.valorTotal : sum + 0, 0);
    this.guiasporanuidade = this.setguiasbycampo(this.guiaAnosAnteriores, 'anuidade');
    this.guiaAnosAnteriores = guias.filter(g => g.situacao == "" && g.dataPagamento == null && g.tipo != "72").sort((g1, g2) => g1.datavencimento < g2.datavencimento ? 1 : -1);
    // this.guiaEmProcessoAdministrativo = guias.filter(g => g.situacao == "PA");
    this.guiaEmProcessoAdministrativo = guias.filter(g => (g.tipocobranca_id && g.tipocobranca_id.id == "56") && g.situacao == '').sort((g1, g2) => g1.datavencimento < g2.datavencimento ? 1 : -1);
  }

  setGuiasEmProcesso(guias) {
    /*let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function anosProcesso(guia) {
      return (
        guia.situacao == "PA"  ) ;
    }*/

    this.guiaEmProcessoAdministrativo = guias.filter(g => (g.tipocobranca_id && g.tipocobranca_id.id == "56") && g.situacao == '');
    console.log('GPA: ', this.guiaEmProcessoAdministrativo);
    this.totalDebitos += this.guiaEmProcessoAdministrativo.reduce((sum, item) => sum + item.valorTotal, 0);
    this.guiaEmProcessoAdministrativo = this.setguiasbycampo(this.guiaEmProcessoAdministrativo, 'processoRefencia');
  }

  setGuiasnegociacao(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function anosAnteriotes(guia) {
      return (
        guia.situacao == "" && guia.dataPagamento == null && guia.tipo == "72");
    }

    this.guiaAnosAnteriores = guiasArr.filter(anosAnteriotes);
    this.totalDebitos += this.guiaAnosAnteriores.reduce((sum, item) => sum + item.valorTotal, 0);
    this.guiasnegociacao = this.setguiasbycampo(this.guiaAnosAnteriores, 'negociacao');
  }

  setguiasbycampo(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  setGuiasCanceladas(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function canceladas(guia) {
      return (
        guia.situacao == "C");
    }

    this.guiaCanceladas = guiasArr.filter(canceladas);
  }

  setParcelasAcrodo(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function guiasNegociacao(guia) {
      return (guia.situacao == "" && guia.dataPagamento == null && guia.tipo == "73" || guia.tipo == "72");
    }

    this.guiasNegociacao = guiasArr.filter(guiasNegociacao);
    //console.log(this.guiasNegociacao);
  }

  setGuiasPagas(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function guiasPagas(guia) {
      return (guia.situacao == "P");
    }

    this.guiasPagas = guiasArr.filter(guiasPagas);
    this.guiasPagas.sort((g1, g2) => g1.dataPagamento < g2.dataPagamento ? 1 : -1);
    // console.log(this.guiasPagas)
    this.totalPago = this.guiasPagas.reduce((sum, item) => sum + item.valorPago, 0);
  }

  setGuiasAgrupadas(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function guiasPagas(guia) {
      return (guia.situacao == "P");
    }

    this.guiasPagas = guiasArr.filter(guiasPagas);
    //console.log(this.guiasPagas);
  }

  setGuiasParcelaAcordo(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function guiasParcelaAcordo(guia) {
      return (guia.tcob == "21");
    }

    this.guiasAcordoParcela = guiasArr.filter(guiasParcelaAcordo);
    //console.log(this.guiasAcordoParcela)
  }

  setTaxas(guias) {
    let guiasArr = Object.keys(guias).map((k) => guias[k]);

    function taxas(guia) {
      return ((guia.tipocobranca_id && guia.tipocobranca_id.anuidade == "T") && guia.situacao != "P");
    }

    this.taxas = guiasArr.filter(taxas);
    //console.log("taxas:" + this.taxas);
  }

  refreshGuias() {
    this.ngOnInit();
  }

  guiaUpdate: Guia;

  openLg(content, guiaUpdate) {
    this.guiaUpdate = guiaUpdate;

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: guiaUpdate.observacao,
        datavencimento: guiaUpdate.datavencimento,
        dataEmissao: guiaUpdate.dataEmissao,

        inscricao: guiaUpdate.inscricao,
        guia: guiaUpdate.guia,
        anuidade: guiaUpdate.anuidade,

        tipo: guiaUpdate.tipo,
        valor: guiaUpdate.valor,
        valorPago: guiaUpdate.valorPago,
        valorTotal: guiaUpdate.valorTotal,


        dataPagamento: guiaUpdate.dataPagamento,

        situacao: guiaUpdate.situacao,
        TipoPessoa: guiaUpdate.tipoPessoa,

        numeroCobranca: guiaUpdate.numeroCobranca,
        moedaguia: guiaUpdate.moedaguia,
        tipoPagamento: guiaUpdate.tipoPagamento,


        moti: guiaUpdate.moti,
        negociacao: guiaUpdate.negociacao,

        bordero: guiaUpdate.bordero,
        anodebito: guiaUpdate.anodebito,

        dataBoleto: guiaUpdate.dataBoleto,
        valorBoleto: guiaUpdate.valorBoleto,
        retorno: guiaUpdate.retorno,


        valorCorrigido: guiaUpdate.valorCorrigido,
        valorMulta: guiaUpdate.valorMulta,
        valorJuros: guiaUpdate.valorJuros,
        codigo: guiaUpdate.codigo,


        item: guiaUpdate.item,
        profissional_id: this.beanId,
        tipocobranca_id: guiaUpdate.tipocobranca_id,
        cobranca_id: guiaUpdate.cobranca_id,
        processoRefencia: guiaUpdate.processoRefencia,
      }
    );

    const activeModal = this.modalService.open(content, {size: 'lg'});
  }

  loadBoletosFromCobranca(cobrancaId: string): void {
    this.boletoService.findByCobranca(cobrancaId).subscribe(response => {
      this.boletos = <any>response;
      console.log(response);

    }, error => {
      console.log(error.error.message);
    });
  }

  updateGuiaOneClick(guiaUpdate) {
    let guia = guiaUpdate as Guia;
    let dataRef = new Date();
    const dt = moment(guia.datavencimento).isAfter(dataRef);

    this.boletoService.findByCobranca(guia.id).subscribe(response => {
      this.boletos = <any>response;
      console.log(response);

      if (this.boletos && this.boletos.length > 0) {
        this.boletos.sort((b1, b2) => b1.id > b2.id ? -1 : 1);
        const last = this.boletos[0];

        const isBefore = moment(last.dataVencimento).isBefore(moment());
        if (isBefore) {
          if (this.beanType == "PessoaJuridicaFormComponent") {
            this.updateGuiaPJOneClick(guiaUpdate);
          } else if (this.beanType == "ProfissionalFormComponent") {
            this.updateGuiaPFOneClick(guiaUpdate);
          } else if (this.beanType == "ConsultorioFormComponent") {
            this.updateGuiaCTOneClick(guiaUpdate);
          }
        } else {
          if (last.url) {
            Swal.fire({
              title: 'Operação não permitida',
              icon: 'warning',
              text: 'Título não vencido, deseja emitir uma 2ª via?',
              showCloseButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Emitir'

            }).then((result) => {
              if (result.value) {
                window.open(guia.url, 'blank');
              }
            });
          } else {
            if (this.beanType == "PessoaJuridicaFormComponent") {
              this.updateGuiaPJOneClick(guiaUpdate);
            } else if (this.beanType == "ProfissionalFormComponent") {
              this.updateGuiaPFOneClick(guiaUpdate);
            } else if (this.beanType == "ConsultorioFormComponent") {
              this.updateGuiaCTOneClick(guiaUpdate);
            }
          }
        }
      } else {
        if (this.beanType == "PessoaJuridicaFormComponent") {
          this.updateGuiaPJOneClick(guiaUpdate);
        } else if (this.beanType == "ProfissionalFormComponent") {
          this.updateGuiaPFOneClick(guiaUpdate);
        } else if (this.beanType == "ConsultorioFormComponent") {
          this.updateGuiaCTOneClick(guiaUpdate);
        }
      }
    }, error => {
      console.log(error.error.message);
    });
  }

  updateGuia(guiaUpdate, observ: string) {
    if (this.beanType == "PessoaJuridicaFormComponent") {
      this.updateGuiaPJ(guiaUpdate, observ);

    } else if (this.beanType == "ProfissionalFormComponent") {
      this.updateGuiaPF(guiaUpdate, observ);

    } else if (this.beanType == "ConsultorioFormComponent") {
      this.updateGuiaConsultorio(guiaUpdate, observ);
    }
  }

  updateGuiaPJ(guiaUpdate, observ: string) {
    this.guiaUpdateForm.patchValue({profissional_id: this.beanId});

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: observ,
        TipoPessoa: 'J'
      }
    );

    const situacao = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('situacao').value;
    const dataPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('dataPagamento').value;
    const valorPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('valorPago').value;

    switch (situacao) {
      case 'P': {
        if ((dataPagamento && String(dataPagamento).length > 0) && valorPagamento) {
          this.executeGuiaPJ();

        } else {
          this.toastr.warning('Informe a data de pagamento e o valor do pagamento.', 'Atenção', {positionClass: 'toast-bottom-full-width'});
        }
        break;
      }

      case 'C': {
        Swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text: 'Deseja realmente cancelar esse débito?',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sim',

        }).then(response => {
          if (response.value) {
            this.executeGuiaPJ();
          }
        });
        break;
      }

      default: {
        this.spinner.show();
        this.executeGuiaPJ();
        break;
      }
    }
  }

  executeGuiaPJ(): void {
    this.spinner.show();
    const value = this.guiaUpdateForm.getRawValue();
    this.cobrancaService.atualizarCobranca(value)
      .subscribe(response => {
        if (response) {
          this.modalService.dismissAll();
          this.loadAllsDebitos();
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
    /*this.cobrancaService.updateguiaeditpj(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;
            this.guias = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.ngOnInit();
            this.spinner.hide();
          }

        }, error1 => {
          console.log(error1.message);
          this.spinner.hide();
        });

      } else {
        this.spinner.hide();
      }
      this.spinner.hide();
    }, error1 => {
      console.log(error1.message);
      this.spinner.hide();
    });*/
  }

  updateGuiaPF(guiaUpdate: Guia, observ: string) {
    this.guiaUpdateForm.patchValue({profissional_id: this.beanId});

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: observ,
        dataEmissao: guiaUpdate.dataEmissao,
        tipoPessoa: 'F',
        processoRefencia: guiaUpdate.processoRefencia,
        valorOriginal: guiaUpdate.valorOriginal
      }
    );

    const situacao = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('situacao').value;
    const dataPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('dataPagamento').value;
    const valorPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('valorPago').value;

    switch (situacao) {
      case 'P': {
        /*if ((dataPagamento && String(dataPagamento).length > 0) && valorPagamento) {
          this.executeEditPF();
        } else {
          this.toastr.warning('Informe a data de pagamento e o valor do pagamento.', 'Atenção', {positionClass: 'toast-bottom-full-width'});
        }*/
        this.toastr.warning('Operação não permitida! Por favor entre em contato com setor de tecnologia.', 'Atenção');
        break;
      }

      case 'C': {
        Swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text: 'Deseja realmente cancelar esse débito?',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sim',

        }).then(response => {
          if (response.value) {
            this.spinner.show();
            this.executeEditPF();
          } else {
            this.spinner.hide();
          }
        });
        break;
      }

      default: {
        this.executeEditPF();
        break;
      }
    }
  }

  executeEditPF(): void {
    /*this.cobrancaService.updateguiaedit(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;
            this.guias = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);

            this.ngOnInit();
            this.spinner.hide();
          }

        }, error1 => {
          console.log(error1.message);
          this.spinner.hide();
        });

      } else {
        this.spinner.hide();
      }

    }, error1 => {
      console.log(error1.message);
      this.spinner.hide();
    });*/
    this.spinner.show();
    const value = this.guiaUpdateForm.getRawValue();
    this.cobrancaService.atualizarCobranca(value)
      .subscribe(response => {
        if (response) {
          this.modalService.dismissAll();
          this.loadAllsDebitos();
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  updateGuiaConsultorio(guiaUpdate, observ: string) {
    this.spinner.show();
    this.guiaUpdateForm.patchValue({profissional_id: this.beanId});

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: observ,
        dataEmissao: guiaUpdate.dataEmissao,
        TipoPessoa: 'C'
      }
    );

    const situacao = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('situacao').value;
    const dataPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('dataPagamento').value;
    const valorPagamento = (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).get('valorPago').value;

    switch (situacao) {
      case 'P': {
        if ((dataPagamento && String(dataPagamento).length > 0) && valorPagamento) {
          this.executeEditConsultorio();
        } else {
          this.toastr.warning('Informe a data de pagamento e o valor do pagamento.', 'Atenção', {positionClass: 'toast-bottom-full-width'});
        }
        break;
      }

      case 'C': {
        Swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text: 'Deseja realmente cancelar esse débito?',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sim',

        }).then(response => {
          if (response.value) {
            this.executeEditConsultorio();
          }
        });
        break;
      }

      default: {
        this.executeEditConsultorio();
        break;
      }
    }
  }

  executeEditConsultorio(): void {
    this.spinner.show();
    const value = this.guiaUpdateForm.getRawValue();
    this.cobrancaService.atualizarCobranca(value)
      .subscribe(response => {
        if (response) {
          this.modalService.dismissAll();
          this.loadAllsDebitos();
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

    /*this.cobrancaService.updateguiaeditct(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;
            this.guias = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);

            this.ngOnInit();
            this.spinner.hide();
          }

        }, error1 => {
          console.log(error1.message);
          this.spinner.hide();
        });

      } else {
        this.spinner.hide();
      }
      this.spinner.hide();
    }, error1 => {
      console.log(error1.message);
      this.spinner.hide();
    });*/
  }

  erros: any;

  updateGuiaPFOneClick(guiaUpdate) {
    this.spinner.show();

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: guiaUpdate.observacao,
        datavencimento: guiaUpdate.datavencimento,
        dataEmissao: guiaUpdate.dataEmissao,

        inscricao: guiaUpdate.inscricao,
        guia: guiaUpdate.guia,
        anuidade: guiaUpdate.anuidade,

        tipo: guiaUpdate.tipo,
        valor: guiaUpdate.valor,
        valorPago: guiaUpdate.valorPago,
        valorTotal: guiaUpdate.valorTotal,


        dataPagamento: guiaUpdate.dataPagamento,

        situacao: guiaUpdate.situacao,
        TipoPessoa: guiaUpdate.tipoPessoa,

        numeroCobranca: guiaUpdate.numeroCobranca,
        moedaguia: guiaUpdate.moedaguia,
        tipoPagamento: guiaUpdate.tipoPagamento,


        moti: guiaUpdate.moti,
        negociacao: guiaUpdate.negociacao,

        bordero: guiaUpdate.bordero,
        anodebito: guiaUpdate.anodebito,

        dataBoleto: guiaUpdate.dataBoleto,
        valorBoleto: guiaUpdate.valorBoleto,
        retorno: guiaUpdate.retorno,

        valorOriginal: guiaUpdate.valorOriginal,
        valorCorrigido: guiaUpdate.valorCorrigido,
        valorMulta: guiaUpdate.valorMulta,
        valorJuros: guiaUpdate.valorJuros,
        codigo: guiaUpdate.codigo,


        item: guiaUpdate.item,
        profissional_id: guiaUpdate.profissional_id,
        tipocobranca_id: guiaUpdate.tipocobranca_id,
        cobranca_id: guiaUpdate.cobranca_id,
      }
    );

    const value = this.guiaUpdateForm.getRawValue();
    this.cobrancaService.atualizarCobranca(value)
      .subscribe(response => {
        if (response) {
          this.modalService.dismissAll();
          this.carregarDebitos();
        }
      });
    /*this.cobrancaService.updateguia(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.spinner.hide();
            this.ngOnInit();
          }

        }, (err) => {
          this.spinner.hide();
          this.erros = err;
          this.toastr.error(this.erros.message, 'Erro ao salvar guia');
        });
      }

    }, (err) => {
      this.spinner.hide();
      this.erros = err;
      this.toastr.error(this.erros.message, 'Erro ao salvar guia');
    });*/
  }

  updateGuiaPJOneClick(guiaUpdate) {
    this.spinner.show();

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: guiaUpdate.observacao,
        datavencimento: guiaUpdate.datavencimento,
        dataEmissao: guiaUpdate.dataEmissao,

        inscricao: guiaUpdate.inscricao,
        guia: guiaUpdate.guia,
        anuidade: guiaUpdate.anuidade,

        tipo: guiaUpdate.tipo,
        valor: guiaUpdate.valor,
        valorPago: guiaUpdate.valorPago,
        valorTotal: guiaUpdate.valorTotal,


        dataPagamento: guiaUpdate.dataPagamento,

        situacao: guiaUpdate.situacao,
        TipoPessoa: guiaUpdate.tipoPessoa,

        numeroCobranca: guiaUpdate.numeroCobranca,
        moedaguia: guiaUpdate.moedaguia,
        tipoPagamento: guiaUpdate.tipoPagamento,


        moti: guiaUpdate.moti,
        negociacao: guiaUpdate.negociacao,

        bordero: guiaUpdate.bordero,
        anodebito: guiaUpdate.anodebito,

        dataBoleto: guiaUpdate.dataBoleto,
        valorBoleto: guiaUpdate.valorBoleto,
        retorno: guiaUpdate.retorno,


        valorCorrigido: guiaUpdate.valorCorrigido,
        valorMulta: guiaUpdate.valorMulta,
        valorJuros: guiaUpdate.valorJuros,
        codigo: guiaUpdate.codigo,


        item: guiaUpdate.item,
        profissional_id: guiaUpdate.profissional_id,
        pessoajuridica_id: guiaUpdate.profissional_id,
        tipocobranca_id: guiaUpdate.tipocobranca_id,
        cobranca_id: guiaUpdate.cobranca_id,
      }
    );

    this.cobrancaService.updateguiapj(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.spinner.hide();
            this.ngOnInit();
          }

        }, (err) => {
          this.spinner.hide();
          this.erros = err;
          this.toastr.error(this.erros.message, 'Erro ao salvar guia');
        });
      }

    }, (err) => {
      this.spinner.hide();
      this.erros = err;
      this.toastr.error(this.erros.message, 'Erro ao salvar guia');
    });

  }

  updateGuiaCTOneClick(guiaUpdate) {
    this.spinner.show();

    (<FormArray>this.guiaUpdateForm.get('guiaList')).at(0).patchValue(
      {
        id: guiaUpdate.id,
        observacao: guiaUpdate.observacao,
        datavencimento: guiaUpdate.datavencimento,
        dataEmissao: guiaUpdate.dataEmissao,

        inscricao: guiaUpdate.inscricao,
        guia: guiaUpdate.guia,
        anuidade: guiaUpdate.anuidade,

        tipo: guiaUpdate.tipo,
        valor: guiaUpdate.valor,
        valorPago: guiaUpdate.valorPago,
        valorTotal: guiaUpdate.valorTotal,


        dataPagamento: guiaUpdate.dataPagamento,

        situacao: guiaUpdate.situacao,
        TipoPessoa: guiaUpdate.tipoPessoa,

        numeroCobranca: guiaUpdate.numeroCobranca,
        moedaguia: guiaUpdate.moedaguia,
        tipoPagamento: guiaUpdate.tipoPagamento,


        moti: guiaUpdate.moti,
        negociacao: guiaUpdate.negociacao,

        bordero: guiaUpdate.bordero,
        anodebito: guiaUpdate.anodebito,

        dataBoleto: guiaUpdate.dataBoleto,
        valorBoleto: guiaUpdate.valorBoleto,
        retorno: guiaUpdate.retorno,


        valorCorrigido: guiaUpdate.valorCorrigido,
        valorMulta: guiaUpdate.valorMulta,
        valorJuros: guiaUpdate.valorJuros,
        codigo: guiaUpdate.codigo,


        item: guiaUpdate.item,
        profissional_id: guiaUpdate.profissional_id,
        pessoajuridica_id: guiaUpdate.profissional_id,
        tipocobranca_id: guiaUpdate.tipocobranca_id,
        cobranca_id: guiaUpdate.cobranca_id,
      }
    );

    this.cobrancaService.updateguiaCT(this.guiaUpdateForm.value).subscribe(response => {
      if (response) {
        this.modalService.dismissAll();

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          if (response) {
            this.activeBean = <any>response;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.spinner.hide();
            this.ngOnInit();
          }

        }, (err) => {
          this.spinner.hide();
          this.erros = err;
          this.toastr.error(this.erros.message, 'Erro ao salvar guia');
        });
      }

    }, (err) => {
      this.spinner.hide();
      this.erros = err;
      this.toastr.error(this.erros.message, 'Erro ao salvar guia');
    });

  }

  goToLinkBoleto(url: string) {
    window.open(url, "_blank");
  }

  openBoletoDialog() {
    const dialogBoletoRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '750px',
    });

    dialogBoletoRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  };

  cancelaGuia(guia) {
    this.spinner.show();
    this.guiaService.delete(guia.id).subscribe(response => {
      this.guiaService.getbyCpad(this.beanId).subscribe(response => {
        this.activeBean = <any>response;
        this.guias = <any>response;

        this.setGuiasEmAberto(this.guias);
        this.setGuiasPagas(this.guias);
        this.setGuiasParcelaAcordo(this.guias);
        this.setTaxas(this.guias);

        this.ngOnInit();
        this.spinner.hide();
        this.toastr.success('', 'Guia excluida com sucesso ');
      });
    });
  }

  onOptionsSelected(value: string) {
    if (value == "cancelada") {
      this.verCancelada = true;
    } else {
      this.verCancelada = false;
    }
  }

  sendBoletoByemail(guia: Guia) {
    if(guia.tipoPessoa && guia.guia && (guia.guia.startsWith("17") || guia.guia.startsWith("22") || guia.guia.startsWith("23") || guia.guia.startsWith("24")) && guia.msgRetorno && guia.msgRetorno === '0') {
      this.spinner.show();
      this.cobrancaService.sendEmailBoletoBB(guia)
        .subscribe(response => {
          if(response) {
            this.spinner.hide();
            this.modalService.dismissAll();
            this.toastr.success('E-mail Enviado com sucesso', 'Boleto: ' + guia.id);
          }
        }, error => {
          this.spinner.hide();
          console.log(error);
          this.toastr.error('Ocorreu um erro na tentativa de enviar o email.', 'Atenção!!!');
        });
    } else {
      Swal.fire({icon: 'warning', title: 'Dados Inválidos', text: 'Por favor verique os campos tipoPessoa e nossoNumero'});
    }
    /*this.cobrancaService.sendEmailBoleto(guia).subscribe(response => {
      this.modalService.dismissAll();
      this.toastr.success('E-mail Enviado com sucesso', 'Boleto: ' + guia.id);
    });*/
  }

  saveGuia() {
    if ((<FormArray>this.guiaForm.get('guiaList')).at(0).get('datavencimento').value !== null) {
      if (this.beanType == "PessoaJuridicaFormComponent") {
        (<FormArray>this.guiaForm.get('guiaList')).controls.forEach(c => {
          const valor = c.get('valor').value;
          c.get('valorOriginal').setValue(valor);
          c.get('valorTotal').setValue(valor);
          c.get('tipoPessoa').setValue('J');
        });
        this.saveGuiaPJ()
      } else if (this.beanType == "ProfissionalFormComponent") {
        (<FormArray>this.guiaForm.get('guiaList')).controls.forEach(c => {
          const valor = c.get('valor').value;
          c.get('valorOriginal').setValue(valor);
          c.get('valorTotal').setValue(valor);
          c.get('tipoPessoa').setValue('F');
        });
        this.saveGuiaPF()
      } else if (this.beanType == "ConsultorioFormComponent") {
        (<FormArray>this.guiaForm.get('guiaList')).controls.forEach(c => {
          const valor = c.get('valor').value;
          c.get('valorOriginal').setValue(valor);
          c.get('valorTotal').setValue(valor);
          c.get('tipoPessoa').setValue('C');
        });
        this.saveGuiaConsultorio()
      }

      this.ngxModal.closeAll();

    } else {
      this.toastr.warning('Para gerar a guia é necessário informar a data.', 'Atenção', {
        progressBar: true,
        closeButton: true
      });
      return;
    }
  }

  retornoBoleto: RetornoBoleto;
  mensagemRetorno: string = '';

  saveGuiaPJ() {
    this.spinner.show();
    this.guiaForm.get('profissional_id').setValue(this.beanId);

    /*FIX NGBDATE*/
    let d: NgbDate = (this.guiaForm.get('guiaList') as FormArray).at(0).value.datavencimento;
    let fixDate: Date = new Date(d.year, d.month - 1, d.day);
    (this.guiaForm.get('guiaList') as FormArray).at(0).get('datavencimento').setValue(fixDate);

    /*ADD MESMA DATA DE VENCIMENTO PARA TODOS OS ITENS*/
    for (let ctrl of (this.guiaForm.get('guiaList') as FormArray).controls) {
      ctrl.get('datavencimento').setValue(fixDate);
      ctrl.get('situacao').setValue('');
      ctrl.get('tipoPessoa').setValue('J');
    }

    // console.log(this.guiaForm.value);
    /*this.cobrancaService.savecobrancapj(this.guiaForm.value).subscribe(response => {
      if (response) {
        let cobrancaSaved: Cobranca = <any>response;

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          if (this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('pj');
          }

          this.ngOnInit();

          this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
            this.retornoBoleto = <any>response as RetornoBoleto;

            console.log(this.retornoBoleto.mensagem);

            if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
              let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
              let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

              let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
              let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

              this.mensagemRetorno = 'EXCEÇÃO: '
                .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                .concat('\n')
                .concat(' RETORNO: ')
                .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
            }

            this.ngxModal.open('retornoModal');

          }, error => {
            console.log(error);
          });

        });
      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });*/

    const cobranca = this.guiaForm.getRawValue();
    cobranca.guiaList.forEach(item => item.valorTotal = item.valor);
    this.cobrancaService.registarCobranca(cobranca).subscribe(response => {
      if (response) {
        let cobrancaSaved = response.content as Cobranca;
        //  console.log(cobrancaSaved);
        this.carregarDebitos();

        /*this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          /!*if(this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('pf');
          }*!/

          this.ngOnInit();

          this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
            this.retornoBoleto = <any>response as RetornoBoleto;

            console.log(this.retornoBoleto.mensagem);

            if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
              let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
              let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

              let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
              let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

              this.mensagemRetorno = 'EXCEÇÃO: '
                .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                .concat('\n')
                .concat(' RETORNO: ')
                .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
            }

            this.ngxModal.open('retornoModal');

          }, error => {
            console.log(error);
          });

        });*/
      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });
  }

  saveGuiaPF() {
    this.spinner.show();
    this.guiaForm.get('profissional_id').setValue(this.beanId);

    let date = (this.guiaForm.get('guiaList') as FormArray).at(0).get('datavencimento').value;
    for (let ctrl of (this.guiaForm.get('guiaList') as FormArray).controls) {
      ctrl.get('datavencimento').setValue(date);
      ctrl.get('situacao').setValue('');
      ctrl.get('tipoPessoa').setValue('F');
    }

    const cobranca = this.guiaForm.getRawValue();
    cobranca.guiaList.forEach(item => item.valorTotal = item.valor);
    this.cobrancaService.registarCobranca(cobranca).subscribe(response => {
      if (response) {
        let cobrancaSaved = response.content as Cobranca;
        //  console.log(cobrancaSaved);
        this.ngOnInit();

        /*this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          /!*if(this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('pf');
          }*!/

          this.ngOnInit();

          this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
            this.retornoBoleto = <any>response as RetornoBoleto;

            console.log(this.retornoBoleto.mensagem);

            if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
              let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
              let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

              let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
              let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

              this.mensagemRetorno = 'EXCEÇÃO: '
                .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                .concat('\n')
                .concat(' RETORNO: ')
                .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
            }

            this.ngxModal.open('retornoModal');

          }, error => {
            console.log(error);
          });

        });*/
      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });
  }

  saveGuiaConsultorio() {
    this.spinner.show();
    this.guiaForm.get('profissional_id').setValue(this.beanId);
    // this.guiaForm.get('consultorio_id').setValue(this.beanId);

    let date = (this.guiaForm.get('guiaList') as FormArray).at(0).get('datavencimento').value;
    date = moment(date).subtract(1, 'month').toDate();
    console.log(date);
    for (let ctrl of (this.guiaForm.get('guiaList') as FormArray).controls) {
      ctrl.get('datavencimento').setValue(date);
      ctrl.get('situacao').setValue('');
      ctrl.get('tipoPessoa').setValue('C');
    }

    const cobranca = this.guiaForm.getRawValue();
    cobranca.guiaList.forEach(item => item.valorTotal = item.valor);
    this.cobrancaService.registarCobranca(cobranca)
      .subscribe(response => {
        if (response) {
          let cobrancaSaved = response.content as Cobranca;
          //  console.log(cobrancaSaved);
          this.carregarDebitos();

          /*this.guiaService.getbyCpad(this.beanId).subscribe(response => {
            this.activeBean = <any>response;
            this.guias = <any>response;

            this.guiaForm.reset();
            this.valorTotal = 0.00;
            this.guiaList = null;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.loadForm();
            this.spinner.hide();
            this.toastr.success('Guia criada com sucesso', 'Guia: ');

            /!*if(this.isGuiasPredefinidas) {
              this.guiasNegociacao = cobrancaSaved.guiaList;
              this.agrupaGuias('pf');
            }*!/

            this.ngOnInit();

            this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
              this.retornoBoleto = <any>response as RetornoBoleto;

              console.log(this.retornoBoleto.mensagem);

              if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
                let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
                let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

                let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
                let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

                this.mensagemRetorno = 'EXCEÇÃO: '
                  .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                  .concat('\n')
                  .concat(' RETORNO: ')
                  .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
              }

              this.ngxModal.open('retornoModal');

            }, error => {
              console.log(error);
            });

          });*/
        } else {
          this.spinner.hide();
          this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
        }
      }, error => {
        console.log('Ocorreu um erro: ', error);
        this.spinner.hide();
        this.carregarDebitos();
      });

    /*this.cobrancaService.savecobrancaconsultorio(this.guiaForm.value).subscribe(response => {
      if (response) {
        let cobrancaSaved: Cobranca = <any>response;

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          if (this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('consultorio');
          }

          this.ngOnInit();

          this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
            this.retornoBoleto = <any>response as RetornoBoleto;

            console.log(this.retornoBoleto.mensagem);

            if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
              let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
              let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

              let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
              let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

              this.mensagemRetorno = 'EXCEÇÃO: '
                .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                .concat('\n')
                .concat(' RETORNO: ')
                .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
            }

            this.ngxModal.open('retornoModal');

          }, error => {
            console.log(error);
          });
        });

      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });*/
  }

  carregarDebitos() {
    this.cobrancaService.selecionarDebitos(this.beanId)
      .subscribe((response) => {
        if (response) {
          this.guias = response.content as Guia[];
          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;
          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');
        }
      });
  }

  saveGuiaPFParcelas() {
    if (this.beanType == "PessoaJuridicaFormComponent") {
      this.saveGuiaPFParcelasPJ();
    }

    if (this.beanType == "ProfissionalFormComponent") {
      this.saveGuiaPFParcelasPF();
    }
  }

  saveGuiaPFParcelasPJ() {
    this.spinner.show();

    /*FIX NGBDATE*/
    let d: NgbDate = (<FormArray>this.guiaForm.get('guiaList')).at(0).value.datavencimento;
    let fixDate: Date = new Date(d.year, d.month - 1, d.day);
    (<FormArray>this.guiaForm.get('guiaList')).at(0).patchValue({
      datavencimento: fixDate
    });


    this.guiaForm.patchValue(
      {
        profissional_id: this.beanId
      }
    );


    if ((<FormArray>this.guiaForm.get('guiaList')).length > 1) {

      for (let i = 0; i < (<FormArray>this.guiaForm.get('guiaList')).length; i++) {
        (<FormArray>this.guiaForm.get('guiaList')).at(i).patchValue({
          datavencimento: fixDate,
          situacao: ''
        });
      }
    }

    this.cobrancaService.saveanuidade2020PJ(this.guiaForm.value).subscribe(response => {
      if (response) {
        let cobrancaSaved: Cobranca = <any>response;

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          /*if(this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('pf');
          }*/

          this.ngOnInit();

        });
      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });
  }

  saveGuiaPFParcelasPF() {
    this.spinner.show();

    this.guiaForm.patchValue(
      {
        profissional_id: this.beanId
      }
    );


    if ((<FormArray>this.guiaForm.get('guiaList')).length > 1) {
      let date = (<FormArray>this.guiaForm.get('guiaList')).at(0).value.datavencimento;

      for (let i = 0; i < (<FormArray>this.guiaForm.get('guiaList')).length; i++) {
        (<FormArray>this.guiaForm.get('guiaList')).at(i).patchValue({
          datavencimento: date,
          situacao: ''
        });
      }
    }

    this.cobrancaService.saveanuidade2020(this.guiaForm.value).subscribe(response => {
      if (response) {
        let cobrancaSaved: Cobranca = <any>response;

        this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.guiaForm.reset();
          this.valorTotal = 0.00;
          this.guiaList = null;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);
          this.loadForm();
          this.spinner.hide();
          this.toastr.success('Guia criada com sucesso', 'Guia: ');

          /*if(this.isGuiasPredefinidas) {
            this.guiasNegociacao = cobrancaSaved.guiaList;
            this.agrupaGuias('pf');
          }*/

          this.ngOnInit();

        });
      } else {
        this.spinner.hide();
        this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
      }
    });
  }

  qtdanuidadeparcelas: string;

  saveGuiaPFParcelada() {
    this.guiaForm.patchValue(
      {
        profissional_id: this.beanId
      }
    );

    this.cobrancaService.saveanuidadeparcelada(this.guiaForm.value, this.qtdanuidadeparcelas).subscribe(response => {
      this.guiaService.getbyCpad(this.beanId).subscribe(response => {
        this.activeBean = <any>response;
        this.guias = <any>response;

        this.setGuiasEmAberto(this.guias);
        this.setGuiasPagas(this.guias);
        this.setGuiasParcelaAcordo(this.guias);
        this.setTaxas(this.guias);
      });
    });

    this.toastr.success('Guia criada com sucesso', 'Guia: ');
    // this.openModalBoletoOpecoes(boletoopcoes);
  }

  agrupaGuia() {
    if (this.beanType == "PessoaJuridicaFormComponent") {
      this.agrupaGuias('pj');
    }

    if (this.beanType == "ProfissionalFormComponent") {
      this.agrupaGuias('pf');
    }

    if (this.beanType == "ConsultorioFormComponent") {
      this.agrupaGuias('consultorio');
    }
  }

  agrupaGuias(tipo: string) {
    this.guiasNegociacao.forEach(x => {
      if (x.id !== null) {
        (<FormArray>this.guiasAgrupadaForm.get('guiaList')).push(this.fb.group({
          id: x.id,
          observacao: x.observacao,
          inscricao: x.inscricao,
          guia: x.guia,
          anuidade: x.anuidade,
          tipo: x.tipo,
          valor: x.valor,
          valorPago: x.valorPago,
          dataEmissao: x.dataEmissao,
          dataPagamento: x.dataPagamento,
          situacao: x.situacao,
          TipoPessoa: x.tipoPessoa,
          numeroCobranca: x.numeroCobranca,
          moedaguia: x.moedaguia,
          tipoPagamento: x.tipoPagamento,
          moti: x.moti,
          negociacao: x.negociacao,
          bordero: x.bordero,
          anodebito: x.anodebito,
          dataBoleto: x.dataBoleto,
          valorBoleto: x.valorBoleto,
          retorno: x.retorno,
          codigo: x.codigo,
          item: x.item,
          profissional_id: this.beanId,
          tipocobranca_id: x.tipocobranca_id,
          cobranca_id: x.cobranca_id,
        }));
      }
    });

    if (tipo === 'pf') {
      this.guiasAgrupadaForm.patchValue({profissional_id: this.beanId, tipoPessoa: 'F'});
    } else if (tipo === 'pj') {
      this.guiasAgrupadaForm.patchValue({
        profissional_id: this.beanId,
        pessoajuridica_id: this.beanId,
        tipoPessoa: 'J'
      });
    } else {
      this.guiasAgrupadaForm.patchValue({profissional_id: this.beanId, consultorio_id: this.beanId, tipoPessoa: 'C'});
    }

    this.cobrancaService.agrupaguias(this.guiasAgrupadaForm.value).subscribe(response => {
      if (response) {
        this.guiasNegociacao = [];
        this.guiasAgrupadaForm.reset();
        this.totalDebitosNegociar = 0.0;
        this.modalService.dismissAll();

        this.toastr.success('Guia criada com sucesso', 'Guia: ');

        /*this.guiaService.getbyCpad(this.beanId).subscribe(response => {
          this.activeBean = <any>response;
          this.guias = <any>response;

          this.setGuiasEmAberto(this.guias);
          this.setGuiasPagas(this.guias);
          this.setGuiasParcelaAcordo(this.guias);
          this.setTaxas(this.guias);

          this.refreshGuias();
          this.ngOnInit();

        }, error => {
          this.toastr.error('Ocorreu um erro no carregamento dos dados.', 'Atenção: ');
          console.log(error.message);
        });*/
        this.refreshGuias();

      }

    }, error => {
      this.guiasNegociacao = [];
      this.modalService.dismissAll();
      this.guiasAgrupadaForm.reset();
      this.totalDebitosNegociar = 0.0;
      this.refreshGuias();
      this.toastr.error('As guias nao foram agrupadas.', 'Atenção: ');
      console.log(error.message);
    });
  }

  geraCDA() {
    if (this.beanType == "PessoaJuridicaFormComponent") {
      this.geraCDAPF()

    }
    if (this.beanType == "ProfissionalFormComponent") {
      this.geraCDAPF()

    }
    if (this.beanType == "ConsultorioFormComponent") {
      this.geraCDAPF()
    }
  }

  geraCDAPF() {
    let control = this.guiasCDAForm.get('guiasParaCDA') as FormArray;
    // Following is also correct
    // let control = <FormArray>this.form.controls['resultList'];

    this.guiasNegociacao.forEach(x => {
      control.push(this.fb.group({

        id: x.id,
        observacao: x.observacao,

        inscricao: x.inscricao,
        guia: x.guia,
        anuidade: x.anuidade,

        tipo: x.tipo,
        valor: x.valor,
        valorPago: x.valorPago,


        dataEmissao: x.dataEmissao,
        dataPagamento: x.dataPagamento,
        datavencimento: x.datavencimento,


        situacao: x.situacao,
        TipoPessoa: x.tipoPessoa,

        numeroCobranca: x.numeroCobranca,
        moedaguia: x.moedaguia,
        tipoPagamento: x.tipoPagamento,


        moti: x.moti,
        negociacao: x.negociacao,

        bordero: x.bordero,
        anodebito: x.anodebito,

        dataBoleto: x.dataBoleto,
        valorBoleto: x.valorBoleto,
        retorno: x.retorno,

        //
        // valorCorrigido: x.valorCorrigido,
        // valorMulta: x.valorMulta,
        // valorJuros: x.valorJuros,
        codigo: x.codigo,


        item: x.item,
        profissional_id: this.beanId,
        tipocobranca_id: x.tipocobranca_id,
        cobranca_id: x.cobranca_id,

      }));
    });

    control.removeAt(control.value.findIndex(control => control.valor === null))

    this.cdaService.geraCDA(this.guiasCDAForm.value, this.beanId).subscribe(response => {
      this.activeBean = <any>response;
      this.guias = <any>response;
      this.guiasNegociacao = null;
      this.guiasNegociacao = [];
      this.resetForm();
      this.modalService.dismissAll();
      this.toastr.success('Gerada com sucesso', 'Certidao de Dívida Ativa ');

      this.cdaService.saveOcorrencia(this.activeUser, "Certidão de dívida ativa emitida por: " + this.activeUser.nome, this.beanId).subscribe(response => {
        this.toastr.success('Ocorrência Gerada', 'Ocorrência gerada com sucesso');

      });


    }, (err) => {
      this.erros = err;
      this.toastr.error(this.erros.message, 'Erro ao gerar ceridão');
      console.log(err)
    });
    this.guiasNegociacao = null;
    this.guiasNegociacao = [];
    this.modalService.dismissAll();


  }

  resetForm() {
    this.guiasCDAForm.reset();
  }

  printBoletoLote() {
    this.guiasNegociacao.forEach(x => {
      window.open(x.url, "_blank");
    });
    this.guiasNegociacao = [];
    this.guiasAgrupadaForm.reset();
    this.totalDebitosNegociar = 0.0;
    this.modalService.dismissAll();
  }

  modalBoleto: any;
  boletoOpcoesActive: Guia;

  openModalBoletoOpecoes(boletoopcoes: any, guia: Guia) {
    this.boletoOpcoesActive = guia;
    // console.log(this.boletoOpcoesActive);
    this.modalBoleto = boletoopcoes;
    //let filter = {whereClauses: []} as FilterDataHql;
    //filter.whereClauses.push({columnName: ''} as WhereClauseHql);


    this.modalService.open(boletoopcoes, {centered: true, size: "lg"});
  }

  negociacaoActive: Negociacao;
  guiasNegoOri: any;
  guiasAPagar: any;

  openModalNegociacao(negociacaomodal: any, negociacao: Negociacao) {
    this.negociacaoActive = negociacao
    this.modalService.open(negociacaomodal, {size: 'lg'});

    this.negociacaoService.findNegociacaoGuiasOri(this.negociacaoActive.id).subscribe(response => {
      this.guiasNegoOri = <any>response;
      console.log(this.negociacoes);
    });

    this.negociacaoService.findNegociacaoGuiasAPagar(this.negociacaoActive.id).subscribe(response => {
      this.guiasAPagar = <any>response;
      console.log(this.negociacoes);

    });
  }

  printBoleto(id: number) {
    this.reportname = "BoletoCobranca"
    this.sharedService.printBoleto(this.reportname, id)
  }

  printExtratoFinanceiro() {
    if (this.router.url.includes('pessoajuridica')) {
      this.reportname = "ExtratoFinanceiroPJ";
    } else {
      this.reportname = "extratoFinanceiroPF";
    }

    this.sharedService.printExtratoFinanceiro(this.reportname, this.beanId)
  }

  selectedOption: TipoCobranca;

  setValor(i) {
    let oldValue = (<FormArray>this.guiaForm.get('guiaList')).at(i).value.valor;
    let valueChange = (<FormArray>this.guiaForm.get('guiaList')).at(i).value.tipocobranca_id.valor;

    (<FormArray>this.guiaForm.get('guiaList')).at(i).patchValue(
      {
        valor: (<FormArray>this.guiaForm.get('guiaList')).at(i).value.tipocobranca_id.valor,
        observacao: (<FormArray>this.guiaForm.get('guiaList')).at(i).value.tipocobranca_id.descricao,
      }
    );

    if (oldValue) {
      if (Number(oldValue) !== Number(valueChange)) {
        this.valorTotal = (this.valorTotal - oldValue) + valueChange;
      }
    } else {
      this.valorTotal += valueChange;
    }
  }

  oldTipoCobrancaValue: number = 0.00;

  setValor2(i) {
    let valor = (<FormArray>this.guiaForm.get('guiaList')).at(i).value.valor;
    if (valor !== this.oldTipoCobrancaValue) {
      this.valorTotal = (this.valorTotal - this.oldTipoCobrancaValue) + valor;
    }
  }

  onFocusInput(posicao: number) {
    this.oldTipoCobrancaValue = (<FormArray>this.guiaForm.get('guiaList')).at(posicao).value.valor;
  }

  deleteGuia(index) {
    let value = (<FormArray>this.guiaForm.get('guiaList')).at(index).value.valor;
    (<FormArray>this.guiaForm.get('guiaList')).removeAt(index);
    this.valorTotal -= value;
  }

  negociacaoParaDesfazer: Negociacao;

  openModalDesfazer(negociacao: Negociacao, modalId: any) {
    this.negociacaoParaDesfazer = negociacao;
    this.modalService.open(modalId);
  }

  desfazerAcordo() {
    this.negociacaoService.findNegociacaoGuiasOri(this.negociacaoParaDesfazer.id).subscribe(response => {
      this.guiasNegoOri = <any>response;

      let list: Array<string> = new Array<string>();
      this.guiasNegoOri.forEach(i => list.push(String(i.id)));

      this.negociacaoService.desfazerAcordo(list, this.negociacaoParaDesfazer.id).subscribe(response => {
        if (response) {
          this.modalService.dismissAll();
          this.toastr.success('Acordo desfeito!', 'Sucesso');
          this.ngOnInit();

        } else {
          this.toastr.warning('O Acordo não foi desfeito!', 'Atenção');
        }

      }, error => {
        console.log(error.message);
      });

    }, error => {
      console.log(error.message);
    });
  }

  getControls() {
    return (<FormArray>this.guiaForm.get('guiaList')).controls;
  }

  getTiposDeCobrana(filterData: FilterData) {
    this.tipocobrancaService.loadByFilter(filterData).subscribe(response => {
      this.tiposDeCobranca = response.content;

      //console.log(this.tiposDeCobranca);
    });
  }

  createItemUpdate(): FormGroup {
    return this.fb.group({
      id: [],
      getMsgRetorno: null,
      url: null,
      inscricao: [],
      guia: [],
      observacao: null,
      anuidade: null,
      datavencimento: null,
      tipo: null,
      valor: null,
      valorPago: null,
      valorOriginal: null,
      descricao: [''],
      dataPagamento: null,
      situacao: [''],
      tipoPessoa: null,
      numeroCobranca: null,
      moedaguia: null,
      tipoPagamento: null,
      tdoc: null,
      banco: null,
      tban: null,
      tcob: null,
      guih: null,
      moti: null,
      negociacao: null,
      bordero: null,
      operacao: null,
      tipocobranca: null,
      anodebito: null,
      valorTotal: null,

      dataBoleto: null,
      valorBoleto: null,
      retorno: null,
      convenio: null,
      carteira: null,
      variacao: null,
      registro: null,


      valorCorrigido: null,
      valorMulta: null,
      valorJuros: null,
      codigo: null,


      dtcr_gui: null,
      dtvr_gui: null,
      vpres_gui: null,
      pgme_gui: null,
      intpar_gui: null,

      item: null,
      profissional_id: this.beanId,
      tipocobranca_id: null,
      cobranca_id: null,
      processoAdministrativo: null,
      processoRefencia: null,
    });
  }

  createItem(): FormGroup {
    return this.fb.group({

      id: [],
      getMsgRetorno: null,
      inscricao: null,
      guia: null,
      anuidade: null,

      tipo: null,
      valor: null,
      valorPago: null,
      valorOriginal: null,


      descricao: [''],


      dataEmissao: null,
      dataPagamento: null,
      datavencimento: null,

      situacao: null,
      tipoPessoa: null,

      numeroCobranca: null,
      moedaguia: null,
      tipoPagamento: null,


      negociacao: null,

      observacao: null,

      bordero: null,
      operacao: null,
      tipocobranca: null,
      anodebito: null,

      dataBoleto: null,
      valorBoleto: null,
      retorno: null,
      convenio: null,
      carteira: null,
      variacao: null,
      registro: null,


      valorCorrigido: null,
      valorMulta: null,
      valorJuros: null,
      codigo: null,

      item: null,
      profissional_id: this.beanId,
      tipocobranca_id: null,
      cobranca_id: null,
      processoAdministrativo: null,
      processoRefencia: null,
    });
  }

  inscricaoDefinitiva: any;
  carteiraDefinitiva: any;
  cartaoIdentificacao: any;

  getInscrdefinitiva() {
    this.inscricaoDefinitiva = this.tiposDeCobranca.find(x => x.id == 15);
    this.carteiraDefinitiva = this.tiposDeCobranca.find(x => x.id == 13);
    this.cartaoIdentificacao = this.tiposDeCobranca.find(x => x.id == 14);
  }

  createItemInscrdefinitiva(inscricaoDefinitiva): FormGroup {
    return this.fb.group({
      anuidade: '2020',
      tipocobranca_id: inscricaoDefinitiva,
    });
  }

  createItemcarteiraDefinitiva(carteiraDefinitiva): FormGroup {
    return this.fb.group({
      anuidade: '2020',
      tipocobranca_id: carteiraDefinitiva,
    });
  }

  createItemcartaoIdentificacao(cartaoIdentificacao): FormGroup {
    return this.fb.group({
      anuidade: '2020',
      tipocobranca_id: cartaoIdentificacao,
    });
  }

  addItem(): void {
    this.guiaList = this.guiaForm.get('guiaList') as FormArray;
    this.guiaList.push(this.createItem());
  }

  negociarDivida() {
    if (this.beanType == "ProfissionalFormComponent") {
      this.negociacaoForm.patchValue(
        {
          profissional_id: this.beanId,
          login: this.activeUser.nome,
          tipoPessoa: "F",
          anuidade: this.anuidadeNegociacao,
          dataPrimeiraparcela: moment().endOf('month').format('YYYY-MM-DD')
        }
      );
      this.negociarPF();
    }

    if (this.beanType == "PessoaJuridicaFormComponent") {
      this.negociacaoForm.patchValue(
        {
          profissional_id: this.beanId,
          login: this.activeUser.nome,
          tipoPessoa: "J",
          dataPrimeiraparcela: moment().endOf('month').format('YYYY-MM-DD')
        }
      );
      this.negociarPJ();
    }
  }

  negociarPF() {
    this.spinner.show();
    this.negociacaoForm.patchValue(
      {
        guiasParaNegociar: this.guiaIdsNegociar,
        valorTotal: this.totalDebitosNegociar,
        anuidade: this.anuidadeNegociacao
      }
    );

    this.negociacaoService.negociar(this.negociacaoForm.value).subscribe(response => {
      this.guiaIdsNegociar = [];
      this.guiasNegociacao = [];

      this.modalService.dismissAll();
      this.spinner.hide()
      this.toastr.success('Negociação fechada', ' ');
      this.ngOnInit();

    }, error => {
      this.spinner.hide()
      this.modalService.dismissAll();
      console.log(error.message);
      this.toastr.error('A tentativa de negociação falhou!', 'Atenção');
      this.ngOnInit();
    });
  }

  negociarPJ() {
    this.spinner.show();
    this.negociacaoForm.patchValue(
      {
        guiasParaNegociar: this.guiaIdsNegociar,
        valorTotal: this.totalDebitosNegociar,
        anuidade: this.anuidadeNegociacao
      }
    );

    this.negociacaoService.negociarPj(this.negociacaoForm.value).subscribe(response => {
      if (response) {
        this.guiasNegociacao = [];
        this.guiaIdsNegociar = [];
        this.spinner.hide();
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('Negociação fechada', ' ');
      }
    }, error1 => {
      this.spinner.hide()
      console.log(error1.message);
      this.modalService.dismissAll();
      this.ngOnInit();
    });


    // this.openModalBoletoOpecoes(boletoopcoes);
  }

  getLookupService(lookupName: string): CommonsService<any> {
    switch (lookupName) {

      default: {
        console.log('serviço não disponibilizado para ', lookupName)
        return this.apiService;
      }
    }
  }

  getDeLookupFilter(lookupValue: any): FilterData {
    switch (lookupValue.name) {
      case 'uf': {
        return this.buildLookupFilter('uf');
      }

      default: {
        console.log('filtro não disponibilizado para ', lookupValue.name)
      }
    }
    return null;
  }


  printConfissaoDivida(id) {
    if (this.beanType == "ProfissionalFormComponent") {

      this.printConfissaoDividaPF(id);
    }

    if (this.beanType == "PessoaJuridicaFormComponent") {

      this.printConfissaoDividaPJ(id);
    }
  }

  printConfissaoDividaPF(id) {
    this.reportname = "termoDeConfissaoDivida";
    this.sharedService.printReport(this.reportname, id)
  }

  printConfissaoDividaPJ(id) {
    this.reportname = "termoDeConfissaoDividaPj";
    this.sharedService.printReport(this.reportname, id)
  }

  isGuiasPredefinidas: boolean = false;

  onsSelectTipoPreDefinido(event) {
    let predefinidos = new Array<TipoCobranca>();

    switch (event.target.value) {
      case 'ANUIDADE2021': {
        this.tiposDeCobranca.forEach(tc => {
          if (Number(tc.id) === 1) {
            predefinidos.push(tc);
          }
        });

        this.isGuiasPredefinidas = true;
        this.getTiposPredefinidos(predefinidos, event.target.value);
        break;
      }

      case 'ANUIDADE2021PARCELADA': {
        this.tiposDeCobranca.forEach(tc => {
          if (Number(tc.id) === 2 || Number(tc.id) === 3 || Number(tc.id) === 4 ||
            Number(tc.id) === 5 || Number(tc.id) === 6 || Number(tc.id) === 7 ||
            Number(tc.id) === 8 || Number(tc.id) === 9) {
            predefinidos.push(tc);
          }
        });

        this.isGuiasPredefinidas = true;
        this.getTiposPredefinidos(predefinidos);
        break;
      }

      case 'PROFISSIONAL': {
        Swal.fire({
          title: 'Guia Predefinida',
          text: 'Deseja parcelar a anuidade?',
          icon: 'question',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              title: 'Guia Predefinida',
              text: 'Informe a quantidade de parcelas.',
              icon: 'warning',
              input: 'number',
              inputAttributes: {'min': '2', 'max': '8'}
            }).then(result => {
              if (result.value) {
                if (Number(result.value) >= 2 && Number(result.value) <= 8) {
                  this.qtdParcelasPredefinida = result.value;

                  this.tiposDeCobranca.forEach(tc => {
                    if (Number(tc.id) === 13 || Number(tc.id) === 14 || Number(tc.id) === 15 || Number(tc.id) === 108) {
                      predefinidos.push(tc);
                    }
                  });

                  this.isGuiasPredefinidas = true;
                  this.getTiposPredefinidos(predefinidos, event.target.value);

                }

              } else {
                $('#guia-modal').modal('hide');
                this.modalService.dismissAll();
                this.toastr.warning('É necessário informar a quantidade de parcelas. Tente novamente!', 'Atenção', {
                  timeOut: 5000,
                  progressBar: true
                });
              }
            });

          } else {
            this.tiposDeCobranca.forEach(tc => {
              if (Number(tc.id) === 13 || Number(tc.id) === 14 || Number(tc.id) === 15 || Number(tc.id) === 1) {
                predefinidos.push(tc);
              }
            });

            this.isGuiasPredefinidas = true;
            this.getTiposPredefinidos(predefinidos);
          }
        });

        /*this.tiposDeCobranca.forEach(tc => {
          if(Number(tc.id) === 13 || Number(tc.id) === 14 || Number(tc.id) === 15 || Number(tc.id) === 31) {
            predefinidos.push(tc);
          }
        });

        this.isGuiasPredefinidas = true;
        this.getTiposPredefinidos(predefinidos);*/
        break;
      }

      case 'EMPRESA': {
        this.tiposDeCobranca.forEach(tc => {
          if (Number(tc.id) === 12 || Number(tc.id) === 17 || Number(tc.id) === 31) {
            predefinidos.push(tc);
          }
        });

        this.isGuiasPredefinidas = true;
        this.getTiposPredefinidos(predefinidos);
        break;
      }

      case 'CONSULTORIO': {
        this.tiposDeCobranca.forEach(tc => {
          if (Number(tc.id) === 17) {
            predefinidos.push(tc);
          }
        });

        this.isGuiasPredefinidas = false;
        this.getTiposPredefinidos(predefinidos);
        break;
      }

      default: {
        this.loadForm();
        this.guiaList = this.guiaForm.get('guiaList') as FormArray;
        this.valorTotal = 0.00;
        this.isGuiasPredefinidas = false;
        break;
      }
    }
  }

  getTiposPredefinidos(tipos: Array<TipoCobranca>, tipo?: string) {
    let date: Date = (<FormArray>this.guiaForm.get('guiaList')).at(0).value.datavencimento;
    this.loadForm();
    this.guiaList = this.guiaForm.get('guiaList') as FormArray;
    this.guiaList.removeAt(0);
    this.valorTotal = 0.00;

    tipos.forEach(t => {
      let f: FormGroup = this.createItem();
      f.get('datavencimento').setValue(date);
      f.get('anuidade').setValue(new Date().getFullYear().toString());
      f.get('observacao').setValue(t.descricao);
      f.get('tipocobranca_id').setValue(t);

      if (tipo && tipo == 'PROFISSIONAL') {
        let tc = this.tiposDeCobranca.find(tc => tc.id == 1);
        let prop = ((tc.valor / 12) * (12 - moment().toDate().getMonth()) / Number(this.qtdParcelasPredefinida));

        f.get('valor').setValue(prop);
        this.valorTotal += prop;

      } else {
        f.get('valor').setValue(t.valor);
        this.valorTotal += t.valor;
      }


      this.guiaList.push(f);
    });
  }


  // PROCESSO ADMINISTRATIVO
  abrirNovoProcesso(): void {
    this.paModalForm.guiasOriginais = this.guiasNegociacao.filter(item => item['situacao'] == '') as Array<Guia>;
    // console.log('Guias para Processo: ', this.paModalForm.guiasOriginais);
    this.paModalForm.onClickAbrirProcesso();
  }

  onClickNovaFase(event: ProcessoAdministrativo) {
    if (event) {
      this.paModalFase.newFase = true;
      this.paModalFase.viewFase = false;
      this.paModalFase.logado = this.activeUser as Usuario;
      this.paModalFase.processoADM = event;
      this.ngxModal.open('modal-faseprocesso');
    }
  }

  onClickVerFase(event: { pa: ProcessoAdministrativo, fase: FaseProcessoAdministrativo }) {
    if (event) {
      event.fase.validade = moment(event.fase.validade).toDate();
      event.fase.data = moment(event.fase.data).toDate();

      this.paModalFase.newFase = false;
      this.paModalFase.viewFase = true;
      this.paModalFase.logado = this.activeUser as Usuario;
      this.paModalFase.processoADM = event.pa;
      this.paModalFase.atualFase = event.fase;
      this.ngxModal.open('modal-faseprocesso');
    }
  }

  onEmitterPAComponents(event: boolean) {
    if (event) {
      this.ngOnInit();
    }
  }

  // ------------------------------------------------------------


  qtdParcelasPredefinida: string;

  saveGuiaPredefinida() {
    if (this.beanType == "ProfissionalFormComponent") {
      this.spinner.show();

      this.guiaForm.patchValue(
        {
          profissional_id: this.beanId
        }
      );

      if ((<FormArray>this.guiaForm.get('guiaList')).length > 1) {
        let date = (<FormArray>this.guiaForm.get('guiaList')).at(0).value.datavencimento;

        for (let i = 0; i < (<FormArray>this.guiaForm.get('guiaList')).length; i++) {
          (<FormArray>this.guiaForm.get('guiaList')).at(i).patchValue({
            datavencimento: date,
            situacao: ''
          });
        }
      }

      this.cobrancaService.saveParcelado(this.guiaForm.value, this.qtdParcelasPredefinida).subscribe(response => {
        if (response) {
          let cobrancaSaved: Cobranca = <any>response;
          this.qtdParcelasPredefinida = undefined;
          $('#guia-modal').modal('hide');
          this.modalService.dismissAll();

          this.guiaService.getbyCpad(this.beanId).subscribe(response => {
            this.activeBean = <any>response;
            this.guias = <any>response;

            this.guiaForm.reset();
            this.valorTotal = 0.00;
            this.guiaList = null;

            this.setGuiasEmAberto(this.guias);
            this.setGuiasPagas(this.guias);
            this.setGuiasParcelaAcordo(this.guias);
            this.setTaxas(this.guias);
            this.loadForm();
            this.spinner.hide();
            this.toastr.success('Guia criada com sucesso', 'Guia: ');
            this.ngOnInit();

            this.retornoBoletoService.loadByCobranca(cobrancaSaved.id).subscribe(response => {
              this.retornoBoleto = <any>response as RetornoBoleto;

              console.log(this.retornoBoleto.mensagem);

              if (this.retornoBoleto.mensagem != '(0) OPERACAO EFETUADA') {
                let ini = this.retornoBoleto.mensagem.indexOf("<EXCECAO>");
                let end = this.retornoBoleto.mensagem.indexOf("</EXCECAO>");

                let iniRet = this.retornoBoleto.mensagem.indexOf("<MENSAGENS><RETORNO>");
                let endRet = this.retornoBoleto.mensagem.indexOf("</RETORNO></MENSAGENS>");

                this.mensagemRetorno = 'EXCEÇÃO: '
                  .concat(ini != -1 ? this.retornoBoleto.mensagem.substring(ini + 9, end) : ' ')
                  .concat('\n')
                  .concat(' RETORNO: ')
                  .concat(iniRet != -1 ? this.retornoBoleto.mensagem.substring(iniRet + 20, endRet) : ' ');
              }

              this.ngxModal.open('retornoModal');

            }, error => {
              console.log(error);
            });

          });
        } else {
          this.spinner.hide();
          this.toastr.error('Ocorreu um erro na criação da guia', 'Guia: ');
        }

      }, error => {
        $('#guia-modal').modal('hide');
        this.modalService.dismissAll();
        this.spinner.hide();
        console.log(error.error.message);
      });
    }
  }

  getTituloNegociacao(id: string): string {
    const ng = this.negociacoes.find(n => n.id == id);
    if (ng) {
      return `${id} -> Via${!ng.origem ? ' Sistema' : ' Portal'}`
    }

    return id;
  }

  onClickRemoverGuias() {
    if (this.guiasNegociacao.length > 0) {
      Swal.fire({
        icon: 'question',
        title: 'Remoção de Títulos',
        text: `Essa ação resultará na remoção de ${this.guiasNegociacao.length} guia(s). Tem certeza que deseja continuar?`,
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          const ids = new Array<string>();
          this.guiasNegociacao.forEach(g => {
            const guia = g as Guia;
            ids.push(guia.id);
          });

          if (ids.length > 0) {
            this.cobrancaService.removerGuias(ids).subscribe((response) => {
              if (response) {
                this.ngOnInit();
              }
            })
          }

        } else {

        }
      });

    } else {
      this.toastr.error('Selecione um ou mais guias primeiro!', 'Atenção', {
        closeButton: true,
        timeOut: 5000,
        progressBar: true
      });
    }
  }

  onClickCopy() {
    if (this.guias.filter(g => g.situacao == '').length > 0) {
      let content = '';
      let html = '<ul>';
      const guias = this.guias.filter(g => g.situacao == '').sort((g1, g2) => g1.tipocobranca_id.id > g2.tipocobranca_id.id ? 1 : -1);
      guias.forEach((g, i) => {
        const txt = `Guia: ${g.id}, Tipo: ${g.tipocobranca_id.descricao}, Anuidade: ${g.anuidade}, Vencimento: ${moment(g.datavencimento).format('DD/MM/YYYY')}, Valor: R$${g.valorTotal.toFixed(2)}`;
        content += (i == this.guias.length) ? txt : (txt + '\n');
        html += (i == this.guias.length) ? `<li style="text-align: left!important;">${txt}</li></ul>` : `<li style="text-align: left!important;">${txt}</li>`;
      });

      Swal.fire({
        icon: 'info',
        title: 'Aréa de Tranferência',
        html: `${html}`,
        width: 'auto',
        showConfirmButton: true,
        confirmButtonText: 'Copiar',
        showCancelButton: false,
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          this.copyService.copy(content);
          this.toastr.success('O conteúdo foi copiado.', 'Tudo Certo.');
        }
      });
    } else {
      this.toastr.warning('Profissioanl não possui débitos em aberto.', 'Atenção.');
    }
  }

  getAnos(): Array<string> {
    const anos = new Array<string>();
    const anoAtual = new Date().getFullYear();
    for (let i = 25; i >= 1; i--) {
      const ano = (anoAtual - i);
      anos.push(ano.toString());
      // console.log(anos);
    }

    anos.push(anoAtual.toString());
    return anos;
  }

  registarBoletoBB() {
    if (this.boletoOpcoesActive && this.boletoOpcoesActive.tipoPessoa && this.boletoOpcoesActive.tipoPessoa.length == 1) {
      this.spinner.show();
      const tipo = this.boletoOpcoesActive.tipoPessoa == 'F' ? 'PF' : (this.boletoOpcoesActive.tipoPessoa == 'J' ? 'PJ' : 'CT');
      this.cobrancaService.registrarBolotoBB(tipo, this.boletoOpcoesActive)
        .subscribe(response => {
          if (response) {
            this.modalService.dismissAll();
            this.spinner.hide();
            this.toastr.success('Boleto Registrado');
            this.guiaService.findById(this.boletoOpcoesActive.id)
              .subscribe(response => {
                this.boletoOpcoesActive = response as unknown as Guia;
                this.onClickPrintBB();
                this.loadAllsDebitos();
              });
          } else {
            if (this.boletoOpcoesActive.url && this.boletoOpcoesActive.url.length > 15) {
              window.open(this.boletoOpcoesActive.url, "_blank");
            }
          }
          this.spinner.hide();
        }, error => {
          console.log(error);
          this.spinner.hide();
        });
    } else {
      this.toastr.warning('O tipo pessoa da guia não esta defido.', 'Atenção!!!');
    }
  }

  rpt: { title: string, name: string, filtro?: FilterDataHql };

  onClickPrintBB() {
    const values = this.boletoOpcoesActive;
    console.log(values);
    if (values && values.guia && (values.guia.startsWith("17") || values.guia.startsWith("22") || values.guia.startsWith("23") || values.guia.startsWith("24") || values.guia.startsWith("25"))) {
      this.rpt = {title: 'Boleto BB', name: 'BoletoBB', filtro: {whereClauses: []} as FilterDataHql};
      const filter = this.rpt.filtro;
      filter.whereClauses.push({
        columnName: 'g.id',
        iniValue: values.id.toString(),
        columnType: ColumnType.LONG,
        columnMatch: ColumnMatch.EQUALS
      } as WhereClauseHql);
      this.sharedService.printFromFilter(this.rpt.name, filter);
    } else {
      this.toastr.warning('Boleto não registrado.', 'Atenção!!!');
    }
  }

  onClickPrintBoletoBancario(boletoOpcoesActive: Guia) {
    const venceu = moment(boletoOpcoesActive.datavencimento).isBefore(moment().toDate());
    if (venceu) {
      this.registarBoletoBB();
    } else {
      if (boletoOpcoesActive.url && boletoOpcoesActive.url.length > 15) {
        window.open(boletoOpcoesActive.url, "_blank");
      }
      if (boletoOpcoesActive.guia && (boletoOpcoesActive.guia.startsWith('17') || boletoOpcoesActive.guia.startsWith('22') || boletoOpcoesActive.guia.startsWith('23') || boletoOpcoesActive.guia.startsWith('24') || boletoOpcoesActive.guia.startsWith('25')) && boletoOpcoesActive.msgRetorno === '0' && boletoOpcoesActive.pixCode) {
        this.onClickPrintBB();
      } else if (!boletoOpcoesActive.url || boletoOpcoesActive.url.toUpperCase() == 'DESATUALIZADO') {
        this.registarBoletoBB();
      } else {
        this.registarBoletoBB();
      }
    }
  }

  onClickEnviarZap(g: Guia) {
    if (!g.guia || g.msgRetorno != '0' || (!g.guia.startsWith('17') && !g.guia.startsWith('22') && !g.guia.startsWith('23') && !g.guia.startsWith('24'))) {
      Swal.fire({title: 'Atenção', icon: 'warning', text: 'Guia não registrada!'}).then();
      return;
    }
    if (!['F', 'C'].includes(g.tipoPessoa)) {
      Swal.fire({title: 'Atenção', icon: 'warning', text: 'o boleto da cobrança só pode ser enviado para profissionais e consultórios!'}).then();
      return;
    }
    this.spinner.show();
    const nome = this.currentTarget['nome'] + (!this.currentTarget['registroCoffito'] ? '' : ' ' + this.currentTarget['registroCoffito']);
    const textoPadrao = `Prezado sr(a). ${nome},\n\nConforme vossa solicitação, segue o link do boleto de cobrança, para abri-lo utilize o seu CPF (somente números).\n\nLink:\n\nhttps://portal.crefito7.gov.br/#/boletos`;
    this.cobrancaService.enviarBoletoPorWhatApps(this.currentTarget['id'], g.id, textoPadrao)
      .subscribe(() => {
        this.spinner.hide();
        Swal.fire({title: 'Tudo Certo!', text: 'O link do boleto foi enviado com sucesso!', icon: 'success'}).then();
      }, error => this.spinner.hide());
  }

  onClickGuiaModal() {
    this.loadForm();
    this.valorTotal = 0.00;
    this.guiaForm.get('profissional_id').setValue(this.beanId);
    this.ngxModal.open('guia-modal');
  }

  tipoNegociacao: string;
  debitosNormais: Array<Guia> = new Array<Guia>();
  debitos2020: Array<Guia> = new Array<Guia>();
  debitos2021: Array<Guia> = new Array<Guia>();
  debitos2022: Array<Guia> = new Array<Guia>();
  debitosRefis2021: Array<Guia> = new Array<Guia>();

  onClickParcelarDebitos() {
    this.debitosNormais = this.guias.filter(g => this.filtroDebitosNormais(g) && g.situacao == '');
    this.debitosNormais = this.debitosNormais.filter(g => g.anuidade != null ? Number(g.anuidade.trim()) > 2017 : false);
    this.debitosNormais.sort((d1, d2) => d1.datavencimento < d2.datavencimento ? 1 : -1);

    const hoje = moment(new Date()).clone();
    const tipos = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '31', '32', '33', '34', '44', '51', '52', '53', '54', '55', '95', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105', '108', '109', '110', '111', '112', '164', '165', '166', '167'];
    this.debitos2020 = this.guias.filter(g => g.anuidade == '2020' && g.situacao == '' && hoje.isAfter(g.datavencimento) && tipos.includes(g.tipocobranca_id.id.toString()));
    this.debitos2020.sort((d1, d2) => d1.datavencimento < d2.datavencimento ? 1 : -1);

    const abaixo2018 = this.debitosNormais.filter(g => g.anuidade != null ? Number(g.anuidade.trim()) < 2018 : false)
    this.debitosRefis2021.push(...abaixo2018);
    Object.keys(this.guiaEmProcessoAdministrativo as unknown as {}).forEach((item) => {
      console.log('Item: ', item);
    })
    this.debitosRefis2021.sort((d1, d2) => d1.datavencimento < d2.datavencimento ? 1 : -1);

    let options = null;
    if (this.debitosNormais.length && this.debitos2020.length && this.debitosRefis2021.length) {
      options = {NORMAL: 'Normal', A2020: 'Anuidade 2020', REFIS2021: 'Refis 2021'};
    } else if (this.debitosNormais.length && this.debitos2020.length && !this.debitosRefis2021.length) {
      options = {NORMAL: 'Normal', A2020: 'Anuidade 2020'};
    } else if (this.debitosNormais.length && !this.debitos2020.length && this.debitosRefis2021.length) {
      options = {NORMAL: 'Normal', REFIS2021: 'Refis 2021'};
    }

    if (!options) {
      if (this.debitosNormais.length) {
        this.tipoNegociacao = 'NORMAL';
      } else if (this.debitos2020.length) {
        this.tipoNegociacao = 'A2020';
      } else if (this.debitosRefis2021.length) {
        this.tipoNegociacao = 'REFIS2021';
      }
      this.ngxModal.open('parcelamento-modal');
    } else {
      Swal.fire({
        title: 'Opções de Negociações',
        icon: 'info',
        input: 'radio',
        inputOptions: options,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          this.tipoNegociacao = result.value;
          this.ngxModal.open('parcelamento-modal');
        }
      }, reason => {
        console.log(reason);
      });
    }
  }

  filtroDebitosNormais(guia: Guia): boolean {
    if (guia) {
      // TODO - Não é permido de maneira alguma
      const etaxa = guia.tipocobranca_id && guia.tipocobranca_id.anuidade == 'T';
      const eRefis = guia.tipocobranca_id.descricao.includes('PARCELA REFIS');
      const eMultaInfracao = guia.tipocobranca_id.id.toString() == '70';
      const eParcelaProcessoCobranca = guia.tipocobranca_id.descricao.includes('PARCELA PROCESSO COBRANCA');
      const eAcordo = ['68', '69'].includes(guia.tipocobranca_id.id.toString());
      const eAgrupamento = guia.tipocobranca_id.id.toString() == '107';

      // TODO - Só podem ser parcelados se for anuidade menor que o ano atual
      const anuidade = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '95', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105'].includes(guia.tipocobranca_id.id.toString());
      const anuidadeIntranet = ['108', '109', '110', '111', '112', '164', '165', '166', '167'].includes(guia.tipocobranca_id.id.toString());
      const anuidadeComplemento = ['51', '52', '53', '54', '55'].includes(guia.tipocobranca_id.id.toString());
      const anuidadeProporcional = ['31', '32', '33', '34', '44'].includes(guia.tipocobranca_id.id.toString());

      // TODO - Só pode ser parcelado se for destrinchado [56]
      const eAgrupamenoDeProcessoAdm = guia.tipocobranca_id.id.toString() == '56';

      if (!etaxa && !eRefis && !eMultaInfracao && !eParcelaProcessoCobranca && !eAcordo && !eAgrupamento) {
        if (anuidade || anuidadeIntranet || anuidadeComplemento || anuidadeProporcional) {
          if (guia.anuidade && guia.anuidade.trim().length == 4) {
            const ano = moment().year();
            if ((anuidade || anuidadeIntranet) && ['2020', '2021', '2022'].includes(guia.anuidade)) {
              return false;
            } else {
              return Number(guia.anuidade) < ano;
            }
          }
        } else if (eAgrupamenoDeProcessoAdm) {
          return true;
        }
      }
    }
    return false;
  }

  getValorTotal(): number {
    let total = 0.00;
    if (this.tipoNegociacao == 'NORMAL') {
      if (this.debitosNormais && this.debitosNormais.length > 0) {
        this.debitosNormais.forEach(g => total += g.valorTotal);
      }
    }
    if (this.tipoNegociacao == 'A2020') {
      if (this.debitos2020 && this.debitos2020.length > 0) {
        this.debitos2020.filter(g => g.tipocobranca_id.id != 56).forEach(g => total += g.valorTotal);
      }
    }
    if (this.tipoNegociacao == 'REFIS2021') {
      if (this.debitosRefis2021 && this.debitosRefis2021.length > 0) {
        this.debitosRefis2021.forEach(g => total += g.valorTotal);
      }
    }
    return total;
  }

  getValorTotalTaxas(): number {
    const hoje = moment(new Date()).clone();
    let total = 0.00;
    if (this.guias && this.guias.length) {
      this.guias.filter(g => g.situacao == '' && (g.tipocobranca_id && g.tipocobranca_id.anuidade == 'T') && hoje.isAfter(g.datavencimento))
        .forEach(g => total += g.valorTotal);
    }
    return total;
  }

  getValorTotal2020(): number {
    const hoje = moment(new Date()).clone();
    let total = 0.00;
    if (this.guias && this.guias.length) {
      this.guias.filter(g => [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(g.tipocobranca_id.id) && g.anuidade == '2020' && hoje.isAfter(g.datavencimento) && g.situacao == '')
        .forEach(g => total += g.valorTotal);
    }
    return total;
  }

  qtdParcelas: number;
  valorParcelas: number;
  porExtenso: string;
  debitos: Array<any>;

  onChangeQtd(event): void {
    const value = event.target.value;
    if (this.tipoNegociacao == 'NORMAL' || this.tipoNegociacao == 'A2020') {
      let max = this.defineTotalParcelasNovaResolucao();
      if (Number(value) <= max) {
        if ((this.getValorTotal() / value) >= 70) {
          this.valorParcelas = this.getValorTotal() / value;
          this.porExtenso = Extenso.porExtenso(this.valorParcelas, Extenso.estilo.monetario).toUpperCase();
          this.buildDebitosParcelados(value, this.valorParcelas);
        } else {
          this.qtdParcelas = 0;
          this.valorParcelas = 0;
          this.debitos = new Array<any>();
          this.porExtenso = '';
        }
      } else {
        this.qtdParcelas = 0;
        this.valorParcelas = 0;
        this.debitos = new Array<any>();
        this.porExtenso = '';
      }
    }

    if (this.tipoNegociacao == 'REFIS2021') {
      const valor = this.getValorTotalRefis2021(value) / value;
      if (valor >= 50) {
        this.valorParcelas = valor;
        this.porExtenso = Extenso.porExtenso(this.valorParcelas, Extenso.estilo.monetario).toUpperCase();
        this.buildDebitosParcelados(value, this.valorParcelas);
      } else {
        this.qtdParcelas = 0;
        this.valorParcelas = 0;
        this.debitos = new Array<any>();
        this.porExtenso = '';
      }
    }
  }

  defineTotalParcelasNovaResolucao(): number {
    if (this.tipoNegociacao == 'NORMAL') {
      if (this.debitosNormais && this.debitosNormais.length > 0) {
        let vencimentos: Array<Date> = new Array<Date>();
        const pas = this.debitosNormais.filter(guia => guia.tipocobranca_id.id == '56');
        const maioVecimento = this.debitosNormais.sort((g1, g2) => g1.datavencimento > g2.datavencimento ? 1 : 2)[0];
        vencimentos.push(moment(maioVecimento.datavencimento).toDate());

        // TODO - Vasculha todos os PAs existentes, para unir os vencimentos das guia de origem
        for (let guia of pas) {
          const obs = guia.observacao;
          const split = obs.split('|');

          for (let str of split) {
            const dataVencimento = str.split('Vencimento[').pop().split(']')[0];
            if (moment(dataVencimento).isValid()) {
              vencimentos.push(moment(dataVencimento).toDate());
            }
          }
        }

        // TODO - Ordena todos os vencimentos de forma decrescente e obtem o primeiro elemento sendo o maior vencimento
        const vencimento = vencimentos.sort((v1, v2) => v1 > v2 ? 1 : -1)[0];

        // TODO - Converte o vencimento em total de dias até a data presente
        const duration = moment.duration(moment(new Date()).diff(vencimento));
        const days = duration.asDays();

        // TODO - Define a quantidade de parcelas de acordo com o total de dias em atraso
        if (days) {
          if (days <= (365 * 2)) {
            return 12;
          } else if (days > (365 * 2) && days <= (365 * 4)) {
            return 24;
          } else if (days > (365 * 4) && days <= (365 * 5)) {
            return 36;
          } else if (days > (365 * 5)) {
            return 48;
          }
        }
      }
    } else if (this.tipoNegociacao == 'A2020') {
      return 5;
    }
    return 12;
  }

  buildDebitosParcelados(qtd: number, valor: number): void {
    this.debitos = new Array<any>();
    for (let i = 0; i < qtd; i++) {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1 + i, 0);

      const debito = {
        parcela: i + 1,
        vencimento: lastDay,
        valor: valor
      };

      this.debitos.push(debito);
    }
  }

  getValorTotalRefis2021(qtd: number): number {
    let total = 0.00;
    let totalRefis = 0.00;
    let totalOriginal = 0.00;
    let totalCorrecao = 0.00;
    let totalMulta = 0.00;
    let totalJuros = 0.00;

    this.debitosRefis2021.forEach(g => {
      totalOriginal += g.valorOriginal;
      totalMulta += g.valorMulta;
      totalJuros += g.valorJuros;
      totalCorrecao += g.valorCorrigido;
      total += g.valorTotal;
    });

    if (qtd == 1) {
      // elimina juros e multas
      totalRefis = totalOriginal;
    } else if (qtd >= 2 && qtd <= 3) {
      // de 2 a 3 calcular 10% da soma(multa + juros)
      const soma = totalMulta + totalJuros;
      totalRefis = totalOriginal + ((soma * 10) / 100);
    } else if (qtd >= 4 && qtd <= 6) {
      // de 4 a 6 calcular 25% da soma(multa + juros)
      const soma = totalMulta + totalJuros;
      totalRefis = totalOriginal + ((soma * 25) / 100);
    } else if (qtd >= 7 && qtd <= 10) {
      // de 7 a 10 calcular 40% da soma(multa + juros)
      const soma = totalMulta + totalJuros;
      totalRefis = totalOriginal + ((soma * 40) / 100);
    } else if (qtd >= 11 && qtd <= 12) {
      // de 7 a 10 calcular 60% da soma(multa + juros)
      const soma = totalMulta + totalJuros;
      totalRefis = totalOriginal + ((soma * 60) / 100);
    }

    return totalRefis;
  }

  onCloseFinishedModal(): void {
    this.qtdParcelas = null;
    this.valorParcela = null;
    this.debitos = new Array<any>();
    this.porExtenso = null;
  }

  onOpenFinishedModal(): void {
    const value = this.defineTotalParcelasNovaResolucao();
    let max = this.defineTotalParcelasNovaResolucao();

    for (let i = 1; i <= value; i++) {
      if (this.tipoNegociacao == 'NORMAL' || this.tipoNegociacao == 'A2020') {
        if ((this.getValorTotal() / i) < 70 && max == value) {
          max = i - 1;
        }
      } else if (this.tipoNegociacao == 'REFIS2021') {
        if ((this.getValorTotalRefis2021(1) / i) < 50 && max == value) {
          max = i - 1;
        }
      }
    }

    const range = document.getElementById('field-rangeParcelas');
    const input = document.getElementById('field-qtdParcelas');
    if (range && input) {
      range.setAttribute('min', '1');
      range.setAttribute('max', String(max));
      // range.setAttribute("maxlength", String(max));

      input.setAttribute('min', '1');
      input.setAttribute('max', String(max));
      // input.setAttribute("maxlength", String(max));
    }
  }

  onClickEfetuarParcelamento(): void {
    Swal.fire({
      title: 'Em Desenvolvimento',
      icon: 'info',
      text: 'Esta ação encontra-se em fase de desenvolvimento e em breve estará disponivel!'
    });
  }

  tipoDebitoInscricao: string;
  espelho: {valor: number, qtdParcelas: number, valorParcela: number};
  parcelaEscolhida: number;
  valorProporcional: number;
  parcelasProporcionais: any[];
  espelhosProporcionais = [
    {valor: 275, qtdParcelas: 5, valorParcela: 55},
    {valor: 252.08, qtdParcelas: 5, valorParcela: 50.41},
    {valor: 229.16, qtdParcelas: 4, valorParcela: 57.29},
    {valor: 206.25, qtdParcelas: 3, valorParcela: 68.75},
    {valor: 183.33, qtdParcelas: 2, valorParcela: 91.66},
    {valor: 160.41, qtdParcelas: 2, valorParcela: 80.20},
    {valor: 137.5, qtdParcelas: 2, valorParcela: 68.75},
    {valor: 114.58, qtdParcelas: 2, valorParcela: 57.29},
    {valor: 91.66, qtdParcelas: 2, valorParcela: 45.83},
    {valor: 68.75, qtdParcelas: 1, valorParcela: 68.75},
    {valor: 45.83, qtdParcelas: 1, valorParcela: 45.83},
    {valor: 22.91, qtdParcelas: 1, valorParcela: 22.91},
  ]

  openModalDebitoInscricao(): void {
    this.tipoDebitoInscricao  = 'PROPORCIONAL';
    this.espelho = this.espelhosProporcionais[new Date().getMonth()];
    this.valorProporcional = this.espelho.valor;
    this.parcelasProporcionais = [];
    for (let i = 1; i <= this.espelho.qtdParcelas; i++) {
      this.parcelasProporcionais.push({qtd: i, valor: 'R$ ' + (this.valorProporcional / i).toFixed(2)});
    }
    this.parcelaEscolhida = this.parcelasProporcionais[0].qtd;
    this.ngxModal.close('guia-modal');
    this.ngxModal.open('debitos-inscricao');
  }

  gerarAnuidadeIncricao(): void {
    Swal.fire({title: 'Atenção', icon: 'info', text: 'Essa operação encontra-se em fase de desenvolvimento e em breve ficará disponível para uso!'})
  }

  gerarAnuidadeAtual() {
    this.ngxModal.close('guia-modal');
    const exists = this.guias.filter(g => g.anuidade == '2024' && g.situacao == '' && (g.tipocobranca_id && [1,72,167].includes(g.tipocobranca_id.id)));
    if(!exists || exists.length == 0) {
      let tipo = true;
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Favor verificar os campos: nome(razao social), cpf(cnpj), email, endereco completo. Caso um desses campos estejam fora do padrão ou sem valor impactará na geração dos débitos.',
        showConfirmButton: true,
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          if(this.beanType == 'ProfissionalFormComponent') {
            this.spinner.show();
            this.cobrancaService.gerarAnuidade2023('PF', {profissionalId: this.beanId, eMetade: null})
              .subscribe(response => {
                if(response) {
                  this.toastr.success('Anuidade gerada com sucesso!', 'Tudo Certo!');
                  this.ngxModal.closeAll();
                  this.ngOnInit();
                }
                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.toastr.error('Houve um problema!', 'Atenção!');
              });
          }

          if(this.beanType == 'PessoaJuridicaFormComponent') {
            this.spinner.show();
            this.cobrancaService.gerarAnuidade2023('PJ', {profissionalId: this.beanId, eMetade: !tipo})
              .subscribe(response => {
                if(response) {
                  this.toastr.success('Anuidade gerada com sucesso!', 'Tudo Certo!');
                  this.ngxModal.closeAll();
                  this.ngOnInit();
                  this.spinner.hide();
                }
                this.spinner.hide();
              }, error => {
                console.log(error);
                this.spinner.hide();
                this.toastr.error('Houve um problema!', 'Atenção!');
              });
          }
        }
      });
    } else {
      this.toastr.warning('Profissional já possui anuidade 2024 gerada!');
    }
  }
}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
